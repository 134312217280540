<template>
  <b-container>
    <b-row class="mt-4 justify-content-center">
      <b-col md="6" v-if="!isEdit">
        <h-card class="shadow-sm h-100">
          <h4 class="text-center">{{ $t('messages.yourMessageTitle') }}</h4>
          <b-row class="flex-column flex-grow-1 justify-content-center mt-3">
            <b-col>
              <audio
                :src="record.src"
                :type="record.mineType"
                id="audio"
                class="d-block w-100 mb-4"
                controls
              ></audio>
            </b-col>
            <b-col>
              <b-row class="align-items-start">
                <b-col xl="5" class="mb-2 mb-xl-0 mt-2">Nom du message</b-col>
                <b-col xl="7">
                  <b-form-group type="text" class="mb-0">
                    <b-form-input
                      id="message-name"
                      :disabled="isEdit"
                      v-model="messageLocal.name"
                      type="text"
                      trim
                      @blur="updateName()"
                      @input="alreadyUsed = false"
                      :state="hasNameError"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="submitted && !$v.messageLocal.name.minLength"
                    >
                      Le nom du message doit comporter au moins 4 caractères
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      v-if="submitted && !$v.messageLocal.name.maxLength"
                    >
                      Le nom du message doit comporter au maximum 60 caractères
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      v-if="submitted && !$v.messageLocal.name.required"
                    >
                      Le nom du message est obligatoire
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback v-if="submitted && alreadyUsed">
                      Le nom du message est déjà utilisé
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                v-if="tags.length > 1"
                class="align-items-center mt-3"
                :class="{ 'mt-4': isEdit }"
              >
                <b-col xl="5" class="mb-2 mb-xl-0">Type de message</b-col>
                <b-col xl="7">
                  <b-form-select
                    v-model="messageLocal.idTag"
                    :disabled="isEdit"
                    :options="tags"
                    value-field="id"
                    text-field="name"
                  ></b-form-select>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </h-card>
      </b-col>
      <b-col
        :md="isEdit ? '8' : '6'"
        class="mt-md-0 mt-lg-0 mt-xl-0"
        :class="{ 'mt-3': !isEdit }"
      >
        <h-card v-if="loaded" class="d-flex flex-column shadow-sm h-100">
          <h4 class="text-center">
            {{
              this.isEdit
                ? 'Planification de ' + this.messageLocal.name
                : 'Planification'
            }}
          </h4>
          <b-row class="mt-2 align-items-center">
            <b-col xl="5" class="mb-2 mb-xl-0">
              <label for="startDate" class="mb-0">
                {{ $t('messages.startDateBroadcast') }}</label
              >
            </b-col>
            <b-col xl="7">
              <b-button-group>
                <b-button
                  @click="startNowBtn"
                  :class="{ active: startDateTrigger === 'now' }"
                  class="btn-group-left"
                  variant="outline-info"
                  >{{ $t('messages.today') }}</b-button
                >
                <v-date-picker
                  :locale="$i18n.locale"
                  is-required
                  :attributes="attrs"
                  :min-date="tomorrowDate"
                  :popover="{
                    visibility: 'click',
                    placement: $screens({
                      default: 'bottom-end',
                      sm: 'bottom-end',
                      md: 'bottom-end',
                      lg: 'bottom-start',
                      xl: 'bottom-start',
                    }),
                  }"
                  :highlight="{ backgroundColor: 'red' }"
                  v-model="messageLocal.startDate"
                  :value="messageLocal.startDate"
                  @input="updateStartDate"
                >
                  <template v-slot="{ inputEvents }">
                    <b-button
                      v-on="inputEvents"
                      @click="startLaterBtn"
                      :class="{ active: startDateTrigger === 'later' }"
                      class="btn-group-right"
                      variant="outline-info"
                    >
                      {{ startDateLabel() }}
                    </b-button>
                  </template>
                </v-date-picker>
              </b-button-group>
            </b-col>
          </b-row>
          <b-row class="mt-4 align-items-center">
            <b-col xl="5" class="mb-2 mb-xl-0">
              <label for="endDate" class="mb-0">{{
                $t('messages.endBroadcast')
              }}</label>
            </b-col>
            <b-col xl="7">
              <b-button-group>
                <b-button
                  @click="noEndBtn"
                  :class="{ active: endDateTrigger === 'noEnd' }"
                  class="btn-group-left"
                  variant="outline-info"
                  >{{ $t('messages.noEnd') }}</b-button
                >
                <v-date-picker
                  :locale="$i18n.locale"
                  is-required
                  :attributes="attrs"
                  :min-date="startDateToJs"
                  :popover="{
                    visibility: 'click',
                    placement: $screens({
                      default: 'bottom-end',
                      sm: 'bottom-end',
                      md: 'bottom-end',
                      lg: 'bottom-start',
                      xl: 'bottom-start',
                    }),
                  }"
                  v-model="messageLocal.endDate"
                  :value="messageLocal.endDate"
                  @input="updateEndDate"
                >
                  <template v-slot="{ inputEvents }">
                    <b-button
                      v-on="inputEvents"
                      @click="endDateBtn"
                      :class="{ active: endDateTrigger === 'date' }"
                      class="btn-group-right"
                      :variant="
                        submitted && !$v.messageLocal.endDate.minValue
                          ? 'outline-danger'
                          : 'outline-info'
                      "
                    >
                      {{ endDateLabel() }}
                    </b-button>
                  </template>
                </v-date-picker>
              </b-button-group>
              <div
                class="d-block invalid-feedback"
                v-if="submitted && !$v.messageLocal.endDate.minValue"
              >
                La date de fin ne peut pas être anterieure à la date de début de
                la diffusion
              </div>
            </b-col>
          </b-row>
          <!-- <b-row class="mt-4 align-items-center">
            <b-col xl="5">
              <label for="endDate">Fréquence :</label>
            </b-col>
            <b-col xl="7">
              <b-form-radio-group
                id="endDate"
                v-model="messageLocal.frequency"
                :options="frequencies"
                buttons
                button-variant="outline-info"
                class="w-100"
              ></b-form-radio-group>
            </b-col>
          </b-row> -->
        </h-card>
        <ScheduleEditSkeleton v-else></ScheduleEditSkeleton>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from 'moment'
import messageService from '@/services/messageService.js'
import ScheduleEditSkeleton from '@/components/skeleton/ScheduleEditSkeleton.vue'

import dateFormat from '@/components/dateFormat.js'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

import { mapState } from 'vuex'
export default {
  components: { ScheduleEditSkeleton },
  props: ['id'],
  data() {
    return {
      base64: null,
      loaded: true,
      alreadyUsed: false,
      submitted: false,
      uploading: false,
      noEndDate: moment('01/01/2099'),
      startDateTrigger: 'now',
      endDateTrigger: 'noEnd',
      frequencies: [
        {
          text: 'Normale',
          value: 0,
        },
        {
          text: 'Fréquent',
          value: 1,
        },
        {
          text: 'Exclusive',
          value: 2,
        },
      ],
      messageLocal: {
        idSchedule: -1,
        name: '',
        startDate: moment(),
        endDate: moment('01/01/2099'),
        frequency: 0,
        idTag: null,
      },
      attrs: [
        {
          dot: {
            color: 'blue',
          },
          dates: new Date(),
        },
      ],
    }
  },
  validations() {
    return {
      messageLocal: {
        name: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(60),
        },
        startDate: {
          required,
        },
        endDate: {
          minValue: (value) =>
            moment(value) >= moment(this.messageLocal.startDate),
        },
      },
    }
  },
  computed: {
    hasNameError() {
      if (this.$v.messageLocal.name.$error || this.alreadyUsed) return false
      else return null
    },
    isEdit() {
      if (this.id) return true
      else return false
    },
    todaysDate() {
      return moment()
    },
    startDateToJs() {
      return new Date(this.messageLocal.startDate)
    },
    todaysDateIso() {
      return moment().toISOString()
    },
    tomorrowDate() {
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(tomorrow.getDate() + 1)
      return tomorrow
    },
    messageName: {
      get() {
        return this.$store.state.message.newMessage.messageName
      },
      set(name) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'messageName',
          value: name,
        })
      },
    },
    startDate: {
      get() {
        return this.$store.state.message.newMessage.startDate
      },
      set(date) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'startDate',
          value: date,
        })
      },
    },
    endDate: {
      get() {
        return this.$store.state.message.newMessage.endDate
      },
      set(date) {
        this.$store.dispatch('message/_setNewMessageProp', {
          key: 'endDate',
          value: date,
        })
      },
    },
    ...mapState({
      record: (state) => state.message.record,
      tags: (state) => state.message.mobileTags,
    }),
  },
  methods: {
    updateName() {
      this.messageName = this.messageLocal.name
    },
    formatDateFr(date) {
      //Already formated
      return moment(date).format('DD/MM/YYYY')
    },
    formatDateEn(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    startNowBtn() {
      this.startDateTrigger = 'now'
      this.messageLocal.startDate = dateFormat.encodeServerDate(new Date())
    },
    startLaterBtn() {
      this.startDateTrigger = 'later'
    },
    noEndBtn() {
      this.endDateTrigger = 'noEnd'
      // eslint-disable-next-line no-console
      this.messageLocal.endDate = dateFormat.encodeServerDate('01/01/2099')
    },
    endDateBtn() {
      this.endDateTrigger = 'date'
      if (
        !this.messageLocal.endDate ||
        moment(this.messageLocal.endDate).format('YYYY') == '2099' ||
        moment(this.messageLocal.endDate).format('YYYY') == '2060'
      )
        this.messageLocal.endDate = dateFormat.encodeServerDate(
          this.messageLocal.startDate
        )
      else
        this.messageLocal.endDate = dateFormat.encodeServerDate(
          this.messageLocal.endDate
        )
    },
    startDateLabel() {
      const startDate = new Date(this.messageLocal.startDate)
      startDate.setHours(0, 0, 0, 0)

      const today = new Date()
      today.setHours(0, 0, 0, 0)

      if (this.isEdit) {
        return startDate === today
          ? this.$t('messages.chooseADate')
          : this.formatDateFr(this.messageLocal.startDate)
      } else {
        if (startDate > today)
          return this.formatDateFr(this.messageLocal.startDate)
        else return this.$t('messages.chooseADate')
      }
    },

    endDateLabel() {
      if (
        moment(this.messageLocal.endDate).format('YYYY') === '2099' ||
        moment(this.messageLocal.endDate).format('YYYY') === '2060'
      )
        return this.$t('messages.chooseADate')
      else return this.formatDateFr(this.messageLocal.endDate)
    },
    cancel() {
      if (this.isEdit) this.$router.push({ name: 'messages' })
      else this.$router.push({ name: 'recorder' })
    },
    onNewRecording(blob) {
      let reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onload = (event) => {
        let base64Local = event.target.result
        base64Local = base64Local.split(',')[1]
        this.base64 = base64Local
      }
    },
    updateStartDate() {
      this.startDate = this.messageLocal.startDate
    },
    updateEndDate() {
      this.endDate = this.messageLocal.endDate
    },
    validateSchedule() {
      this.uploading = true
      this.submitted = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const schedule = {
          content: this.isEdit ? null : this.base64,
          size: this.isEdit ? 0 : this.record.size,
          idSchedule: this.messageLocal.idSchedule,
          name: this.messageLocal.name,
          sType: 5,
          startDate: dateFormat.encodeServerDate(this.messageLocal.startDate),
          endDate: dateFormat.encodeServerDate(this.messageLocal.endDate),
          frequency: this.messageLocal.frequency,
          idTag: this.messageLocal.idTag,
          idBrand: this.$store.state.user.user.visibleBrands[0].id,
        }
        if (this.isEdit) this.editScheduleRequest(schedule)
        else this.createScheduleRequest(schedule)
      } else {
        this.uploading = false
      }
    },
    async createScheduleRequest(schedule) {
      try {
        const res = await messageService.createSchedule(schedule)
        if (res && res.data) {
          this.alreadyUsed = false
          this.$toast.success('Le message a été planifié avec succès')
          this.$router.push({ name: 'messages' })
          window.scrollTo(0, 0)
          this.uploading = false
        }
      } catch (err) {
        if (err.response.status === 416)
          this.$toast.error(
            `Limite dépassée dans la période selectionnée. Votre magasin a une limite de ${err.response.data.errorMessage} messages différents diffusés par jour.`
          )
        else if (err.response.status === 409) {
          this.$toast.error(
            'Le nom du message choisi est déjà utilisé, veuillez choisir un autre nom'
          )
          this.alreadyUsed = true
        } else
          this.$toast.error(
            "Une erreur s'est produite. Veuillez contacter le support technique ou réessayer ultérieurement."
          )

        this.uploading = false
      }
    },
    async editScheduleRequest(schedule) {
      try {
        const res = await messageService.editSchedule(schedule)
        if (res && res.data) {
          this.$toast.success('La planification a été éditée avec succès')
          this.$router.push({ name: 'messages' })
          window.scrollTo(0, 0)
          this.uploading = false
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        if (err.response.status === 416)
          this.$toast.error(
            `Limite dépassée dans la période selectionnée. Votre magasin a une limite de ${err.response.data.errorMessage} messages différents diffusés par jour.`
          )
        else
          this.$toast.error(
            "Une erreur s'est produite. Veuillez contacter le support technique ou réessayer ultérieurement."
          )

        this.uploading = false
      }
    },
    editMap(schedule) {
      let endDate = moment(schedule.endDate).format('YYYY')
      if (endDate === '2099' || endDate === '2060')
        this.endDateTrigger = 'noEnd'
      else this.endDateTrigger = 'date'
      if (
        this.formatDateFr(schedule.startDate) ===
        this.formatDateFr(this.todaysDate)
      )
        this.startDateTrigger = 'now'
      else this.startDateTrigger = 'later'
      Object.assign(this.messageLocal, schedule)
      this.messageLocal.startDate = moment(schedule.startDate)
      this.messageLocal.endDate = moment(schedule.endDate)
    },
  },
  async created() {
    if (this.isEdit) {
      this.loaded = false
      const idSchedule = this.$route.params.id
      const res = await messageService.getSchedule(idSchedule)
      this.editMap(res.data)
      this.loaded = true
    }
  },
  async mounted() {
    if ((!this.record.blob || !this.record.blob.size) && !this.isEdit) {
      this.$router.push({ name: 'recorder' })
    }
    // if (!this.isEdit) {
    //   this.onNewRecording(this.record.blob)
    // }
    if (this.startDateTrigger === 'now')
      this.messageLocal.startDate = this.todaysDate
    if (this.tags) this.messageLocal.idTag = this.tags[0].id
  },
}
</script>

<style lang="scss" scoped>
#audio {
  outline: none;
}
.btn-group {
  width: 100%;
}
.btn-group .btn-group-left {
  border-radius: 4px 0 0 4px !important;
}
.btn-group .btn-group-right {
  border-radius: 0 4px 4px 0 !important;
}
body {
  background: red !important;
}
.mask {
  display: none;
  transition: opacity 2s;
  z-index: 9;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.3);
}

@media (max-width: 399.5) {
  .btn-mobile {
    width: 100%;
  }
}
</style>
